<script setup>
import { JBanner, JBannerSceleton } from 'media-flow-ui'
import { toRefs, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { usePageBuilder } from '~/stores/page-builder'
import { useUrlBuilder } from '~/stores/url-builder'

const urlBuilder = useUrlBuilder()
const { getProjectId } = storeToRefs(urlBuilder)

import StaticBanner from '~~/models/layout/components/banner/StaticBanner'
import DynamicBanner from '~~/models/layout/components/banner/DynamicBanner'
import BannerStyle from '~~/models/layout/components/banner/BannerStyle'
import AnalyticsHandler from '~/models/analytics/handler/Handler'

const pageBuilderStore = usePageBuilder()
const { deviceType } = storeToRefs(pageBuilderStore)

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  rowOrder: {
    type: Number,
    required: true,
  }
})

const showSceleton = ref(true)
const bannerData = ref({})
const bannerStyle = ref(new BannerStyle())
const vpAppsBanner = ref(null)
const analyticsHandler = ref(null)

const { path } = useRoute()

const demoBanner = ref({
  img: 'https://cdn-dev.vpapps.cloud/jzpEGA8MnmX/JkvEjBQEXV6/eb968a53-1133-4f06-aec0-87b011b12505.png?q=80',
  alt: '',
  imgTo: '',
  textTo: null,
  title: 'Čo sa urodilo za volantom:',
  description:
    'Čo sa urodilo za volantom: Prehľad totálnych bizarností, ktorým sa ani nechce veriť!',
  userEngagements: {},
  linkType: 2,
  wrapperName: 'div',
  socialMedia: { items: [] },
  isRichText: false,
  videoSrc: null,
  autostart: false,
  canControlAudio: false,
  buttons: [],
})

const { data } = toRefs(props)

const style = new BannerStyle()
style.fromQuery(data.value?.style)
bannerStyle.value = style

await useAsyncData(async () => {


  if (data.value.props.isDynamic) {
    const queryId = data.value.props.settings?.query.id
    const contentId =
      data.value.props.settings?.contentId ||
      data.value.props.settings?.groupInstanceId
    const params = {
      take: 1, //data.value.mediaNumber, //1
    }

    const banner = new DynamicBanner()

    let response
    const res = await pageBuilderStore.actionGetQueryDataInitClient(queryId, params, contentId, false, props.rowOrder)

    if (res) {
      response = res.response
    }

    if (response && response !== -1) {
      banner.fromQuery(response.data[0], data.value.props, deviceType.value)
      bannerData.value = banner
      showSceleton.value = false
    }
  } else {
    const banner = new StaticBanner()
    banner.fromQuery(data.value)
    bannerData.value = banner
    showSceleton.value = false
  }

  // return bannerData.value

})

const getAnalyticsContent = (event) => {
  const { img, title, description, videoSrc } = bannerData.value

  const content = {
    title,
    description,
    media: img || videoSrc || '',
    rowIndex: props.rowOrder,
    event: event
  }

  return content
}

const onBannerClick = () => {
  const content = getAnalyticsContent('BannerClick')

  analyticsHandler.value.sendBannerClick(content)
}

const onBannerView = () => {
  const content = getAnalyticsContent('BannerView')

  analyticsHandler.value.sendBannerView(content)
}

onMounted(() => {
  analyticsHandler.value = new AnalyticsHandler()

  useIsElementOnView(vpAppsBanner.value, onBannerView, true)
})

</script>

<template>
  <div class="h-full w-full" ref="vpAppsBanner" @click="onBannerClick">

    <!-- <Head v-if="bannerData?.img">
      <Link rel="preload" as="image" :href="bannerData?.img" />
    </Head> -->
    <!-- <pre class="text-red-200">
      {{ bannerData }}
    </pre> -->

    <JBannerSceleton v-show="showSceleton" :data="demoBanner"
      :has-overlay="bannerStyle?.imageStyle?.blur > 0 ? true : false" :custom-wrapper="demoBanner?.wrapperName"
      :title-style="bannerStyle.titleStyle" :description-style="bannerStyle.descriptionStyle"
      :content-style="bannerStyle.contentStyle" :wrapper-style="bannerStyle.wrapperStyle"
      :content-classes="bannerStyle.contentClasses" :autoplay="demoBanner.autostart"
      :canControlAudio="demoBanner.canControlAudio" :buttons="demoBanner.buttons" />
    <JBanner v-show="!showSceleton" :data="bannerData" :has-overlay="bannerStyle?.imageStyle?.blur > 0 ? true : false"
      :custom-wrapper="defineNuxtLink({})" :title-style="bannerStyle.titleStyle"
      :description-style="bannerStyle.descriptionStyle" :content-style="bannerStyle.contentStyle"
      :wrapper-style="bannerStyle.wrapperStyle" :content-classes="bannerStyle.contentClasses"
      :autoplay="bannerData.autostart" :can-control-audio="bannerData.canControlAudio" :buttons="bannerData.buttons" />
  </div>
</template>
